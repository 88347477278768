import React from "react";
import imgM from "../../assets/image/instructional/png/content-1-mobile.png";

const Content1 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row justify-content-center justify-content-lg-start">
          {/* Image Section */}
          <div className="col-xl-4 col-xs-4">
            <div>
              <div className="img-2">
                <img
                  className="w-100"
                  src={imgM}
                  alt="Digma Interactive Instructional Videos"
                  data-aos="fade-right"
                  data-aos-delay={500}
                />
              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="col-lg-7 col-md-8 col-xs-10 ml-lg-10 ml-xl-10">
            <div className="pt-7 pb-7 pb-lg-17 pl-sm-5 pl-md-9 pl-lg-10">
              <h3
                className="font-size-11 mb-9 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18 "
                data-aos="fade-up"
                data-aos-delay={300}
              >
                Step by step...
              </h3>
              <p
                className="font-size-7 mb-0 pr-xl-13"
                data-aos="fade-up"
                data-aos-delay={600}
              >Our interactive overlays are an indispensable tool for upgrading your video content into an entertaining and practical interactive experience. Digma is guaranteed to enhance and invigorate the process of your audience reaching their chosen goals, every step of the way.</p>
                        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content1;
