import React from "react";
import { Helmet } from "react-helmet";
import {Link} from "gatsby";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/instructional/Hero";
import Wave from "../../sections/common/Wave";
import Content1 from "../../sections/instructional/Content1";
import VideoDemo from "../../sections/common/VideoDemoNew";
import SEO from '../../components/SEO';
import imgC from "../../assets/image/instructional/png/content-1-mobile.png";

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Instructional - Guide Your Users Through Your Video"
          description="DIGMA’s innovative and unrivalled technology is an absolute must for any company specialising in producing instructional videos."
          image={imgC}
        />             
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/instructional">Instructional</Link>
              </li>              
              </ol>
           </nav>
        </div>
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=is'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=is'}></script>         
         
        </Helmet>                      
        <Hero />
        <VideoDemo id="demo" title="" name="" projectId="91C5D726-93A4-46BB-86C6-6DF0AD418803" />
        <Wave color="#F7F9FC" /> 
        <Content1 className="grey pt-10 pt-md-20 pt-lg-15 pb-9 pb-md-14 pb-lg-35 overflow-hidden position-relative" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
